<template>
    <b-card title="Billing">
        <div style="display: flex; flex-direction: column;">
            <label for="month-picker" style="font-size: small;">Select a Month:</label>
            <span class="custom-search d-sm-flex">
                <div @click="openCalendar" style="position: relative;">
                    <input
                        id="month-picker"
                        type="month"
                        class="form-control d-inline-block dropdown-size"
                        ref="monthPicker"
                        v-model="selectedMonth"
                        @change="handleMonthChange"
                    />
                </div>
                <div class="search-gap">
                </div>
                <b-button class="dropdown-size mt-1 mt-sm-0" v-b-modal.modal-generate-report @click="getReport">Generate Report</b-button>
            </span>
        </div>
  
        <!-- search input -->
        <div class="mt-2 custom-search d-sm-flex"> 
            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block dropdown-size"
                    />
                </div>
            </b-form-group>

            <div class="search-gap">
            </div>

            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <v-select
                        class="dropdown-size"
                        placeholder="Select a payment type"
                        v-model="type"
                        :options="types"
                        :reduce="name=>name.value"
                        label="name"
                        @input="getFilterByType()"
                    />
                </div>
            </b-form-group>

            <div class="search-gap">
            </div>
            
            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <v-select
                        class="dropdown-size"
                        placeholder="Select a gender"
                        v-model="gender"
                        :options="genders"
                        :reduce="name=>name.value"
                        label="name"
                        @input="getFilterByGender()"
                    />
                </div>
            </b-form-group>
        </div>
        <div class="custom-search d-sm-flex">
            <b-form-group>
                <b-form-datepicker
                    class="dropdown-size"
                    id="example-datepicker"
                    v-model="start_date"
                    placeholder="Select start date"
                    @input="getFilterByDate()"
                />
            </b-form-group>

            <div class="search-gap">
            </div>

            <b-form-group>
                <b-form-datepicker
                    class="dropdown-size"
                    id="example-datepicker-1"
                    v-model="end_date"
                    placeholder="Select end date"
                    @input="getFilterByDate()"
                />
            </b-form-group>
        </div>
    
        <!-- table -->
        <div v-if="!userLoading && !userError">
            <vue-good-table
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm 
                }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
                styleClass="vgt-table striped condensed no-outline"
            >
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <!-- Column: User -->
                    <span
                        v-if="props.column.field === 'user.first_name'"
                        class="text-nowrap"
                    >
                        <router-link :to="{ path: '/admin/user/profile/' + props.row.user.profile_id }">
                            <span style="display: flex; flex-direction: column;">
                                <span class="text-nowrap">{{ props.row.user.first_name }} {{ props.row.user.last_name }}</span>
                                <span class="text-nowrap">{{ props.row.user.profile_id }}</span>
                            </span>
                        </router-link>
                    </span>
                    
                    <!-- Column: Index -->
                    <span v-else-if="props.column.field === 'index'">
                        <span>{{props.row.originalIndex+1}}</span>
                    </span>

                    <!-- Column: Payment Type -->
                    <span v-else-if="props.column.field === 'payment_type'">
                        <span v-if="props.row.payment_type === 'contact_request'"><b-badge pill variant="light-success">Pay for Phone</b-badge></span>
                        <span v-else><b-badge pill variant="light-info">Profile Upgrade</b-badge></span>
                    </span>

                    <!-- Column: Grand Total -->
                    <span v-else-if="props.column.field === 'grand_total'">
                        <span>BDT <span style="font-weight: 600;">{{ props.row.grand_total }}</span></span>
                    </span>

                    <!-- Column: Payment Breakdown -->
                    <span v-else-if="props.column.field === 'breakdown'">
                        <span style="display: flex; flex-direction: column;">
                            <span>Charge: <span style="font-weight: 500;">{{ props.row.amount }}</span></span>
                            <span>Vat: <span style="font-weight: 500;">{{ props.row.vat }}</span></span>
                        </span>
                    </span>

                    <!-- Column: Timestamp -->
                    <span v-else-if="props.column.field === 'created_on'">
                        <span>{{ getTimeStamp(props.row.created_on) }}</span>
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                        <span v-b-modal.modal-receipt @click="getReceiptInfo(props.row)" style="font-size: small; font-weight: 500; border-bottom: 1px solid;">
                            Download Receipt
                        </span>
                    </span>
            
                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
        
                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['3','5','10','50','100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>

        <div v-else class="loading-user">
            <img src="../../../../assets/images/logo/logo.png" alt="">
            <div class="load-6">
                <div class="letter-holder">
                    <div class="l-1 letter">P</div>
                    <div class="l-2 letter">l</div>
                    <div class="l-3 letter">e</div>
                    <div class="l-4 letter">a</div>
                    <div class="l-5 letter">s</div>
                    <div class="l-6 letter mr-1">e</div>
                    <div class="l-7 letter">W</div>
                    <div class="l-8 letter">a</div>
                    <div class="l-9 letter">i</div>
                    <div class="l-10 letter">t</div>
                    <div class="l-11 letter">.</div>
                    <div class="l-12 letter">.</div>
                    <div class="l-13 letter">.</div>
                </div>
            </div>
        </div>

        <!-- Receipt Modal -->
        <b-modal
            id="modal-receipt"
            ref="my-modal"
            title="View Payment Receipt"
            size="lg"
            centered
            cancel-variant="outline-secondary"
            hide-footer
        >
            <span style="margin-top: 12px; display: flex; flex-direction: column;">
                <span style="width: 100%; display: flex; align-items: center; justify-content: center; background-color: beige; padding: 16px 0px; border-radius: 6px;">
                    <span @click="download('receiptbody')" style="padding: 8px 16px; background-color: #920809; color: white; border-radius: 4px; width: 105px; cursor: pointer;">Download</span>
                </span>

                <span>
                    <span style="width: 100%; height: 100%; background-color: white;">
                        <span id="receiptbody">
                            <span style="display: flex; justify-content: space-between; font-weight: 600; font-size: medium; color: black;">
                                <span>Billing Invoice</span>
                                <span>Bill ID: {{ receipt.id }}</span>
                            </span>
                            <span style="margin-top: 28px; display: flex; flex-direction: column; color: black;">
                                <span style="font-weight: 500;">Invoice To</span>
                                <span style="font-weight: 600; font-size: medium; margin-top: 4px;">{{ receipt.invoice_to }}</span>
                            </span>
                            <span style="margin-top: 28px; display: flex; justify-content: space-between; font-weight: 600; padding-bottom: 5px; color: black;">
                                <span>Details</span>
                                <span>Amount (BDT)</span>
                            </span>
                            <div style="width: 100%;">
                                <div style="border-bottom: 1.5px solid; color: black;"></div>
                            </div>
                            <span style="margin-top: 16px; display: flex; justify-content: space-between; font-weight: 500; color: black;">
                                <span>1. Charge</span>
                                <span>{{ receipt.charge }}</span>
                            </span>
                            <span style="margin-top: 6px; display: flex; justify-content: space-between; font-weight: 500; color: black; padding-bottom: 16px;">
                                <span>2. Vat</span>
                                <span>{{ receipt.vat }}</span>
                            </span>
                            <div style="width: 100%;">
                                <div style="border-bottom: 1.5px solid; color: black;"></div>
                            </div>
                            <span style="margin-top: 5px; display: flex; justify-content: space-between; font-weight: 600; font-size: medium; color: black;">
                                <span>Total</span>
                                <span>{{ receipt.grand_total }}</span>
                            </span>
                            <span>
                                <span style="margin-top: 80px; display: flex; flex-direction: column; font-weight: 600; font-size: medium; color: black; width: 100%; text-align: center;">
                                    <span>*** Generated By BiyerJuti ***</span>
                                    <span style="margin-top: 16px; margin-bottom: 8px;">***</span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </b-modal>

        <!-- Monthly Report -->
        <b-modal
            id="modal-generate-report"
            ref="my-modal"
            title="View Monthly Report"
            size="lg"
            centered
            cancel-variant="outline-secondary"
            hide-footer
        >
            <span style="margin-top: 12px; display: flex; flex-direction: column;">
                <span v-if="result" style="width: 100%; display: flex; align-items: center; justify-content: center; background-color: beige; padding: 16px 0px; border-radius: 6px;">
                    <span @click="download('reportbody')" style="padding: 8px 16px; background-color: #920809; color: white; border-radius: 4px; width: 105px; cursor: pointer;">Download</span>
                </span>

                <span v-if="result">
                    <span style="width: 100%; height: 100%; background-color: white;">
                        <span id="reportbody">
                            <span style="display: flex; justify-content: space-between; font-weight: 600; font-size: medium; color: black;">
                                <span>Monthly Report</span>
                                <span>Month: {{ result.monthName }}, {{ result.year }}</span>
                            </span>
                            <span style="margin-top: 28px; display: flex; justify-content: space-between; font-weight: 600; padding-bottom: 5px; color: black;">
                                <span>Details</span>
                                <span>Amount (BDT)</span>
                            </span>
                            <div style="width: 100%;">
                                <div style="border-bottom: 1.5px solid; color: black;"></div>
                            </div>
                            <span style="margin-top: 16px; display: flex; justify-content: space-between; font-weight: 500; color: black;">
                                <span>1. Charge</span>
                                <span>{{ result.total_amount }}</span>
                            </span>
                            <span style="margin-top: 6px; display: flex; justify-content: space-between; font-weight: 500; color: black; padding-bottom: 16px;">
                                <span>2. Vat</span>
                                <span>{{ result.total_vat }}</span>
                            </span>
                            <div style="width: 100%;">
                                <div style="border-bottom: 1.5px solid; color: black;"></div>
                            </div>
                            <span style="margin-top: 5px; display: flex; justify-content: space-between; font-weight: 600; font-size: medium; color: black;">
                                <span>Total</span>
                                <span>{{ result.total_grand_total }}</span>
                            </span>
                            <span>
                                <span style="margin-top: 80px; display: flex; flex-direction: column; font-weight: 600; font-size: medium; color: black; width: 100%; text-align: center;">
                                    <span>*** Generated By BiyerJuti ***</span>
                                    <span style="margin-top: 16px; margin-bottom: 8px;">***</span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
                <span v-else>
                    <span style="display: flex; font-weight: 500; font-size: medium; color: black; width: 100%; justify-content: center;">
                        <span style="margin-top: 80px; text-align: center;">No report to show!</span>
                    </span>
                    <span>
                        <span style="margin-top: 80px; display: flex; flex-direction: column; font-weight: 600; font-size: medium; color: black; width: 100%; text-align: center;">
                            <span>*** Generated By BiyerJuti ***</span>
                            <span style="margin-top: 16px; margin-bottom: 8px;">***</span>
                        </span>
                    </span>
                </span>
            </span>
        </b-modal>
    </b-card>
</template>
  
<script>
import { BAvatar, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea, BFormDatepicker } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import store from '@/store/index';
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import moment from 'moment';
import html2pdf from 'html2pdf.js';

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea,
        BFormDatepicker
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            selectedMonth: "",
            result: null,
            monthNames: [
                "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
            ],
            showEdit: true,
            showDelete: false,
            type: null,
            gender: null,
            start_date: null,
            end_date: null,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'index',
                    type:'number',
                    tdClass: 'text-center ',
                    thClass:'text-center'
                },
                {
                    label: 'User',
                    field: 'user.first_name',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Payment Type',
                    field: 'payment_type',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Paid Amount',
                    field: 'grand_total',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Payment Breakdown',
                    field: 'breakdown',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Time & Date',
                    field: 'created_on',
                    tdClass: 'text-center',
                    thClass:'text-center'
                }
            ],
            rows: [],
            searchTerm: '',
            types: [
                {
                    name: 'Pay for Phone',
                    value: 'contact_request'
                },
                {
                    name: 'Profile Upgrade',
                    value: 'upgrade_premium'
                }
            ],
            genders: [
                {
                    name: 'Male',
                    value: 'male'
                },
                {
                    name: 'Female',
                    value: 'female'
                }
            ],
            receipt: {
                id: null,
                invoice_to: null,
                charge: null,
                vat: null,
                grand_total: null
            }
        }
    },

    computed: {
        ...mapState('hearts', ["billing_list","billingError","billingLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },

    watch: {
        billing_list(newValue, oldValue) {
            this.rows=this.billing_list
        },

        billingError(newValue, oldValue) {},

        billingLoading(newValue, oldValue) {}
    },

    created() {
        this.$store.dispatch('hearts/fetchBillingList')
        this.rows = this.billing_list
        let permissions = JSON.parse(localStorage.getItem('permissions'))
        if(this.showEdit || this.showDelete){
            this.columns.push({
                label: 'Action',
                field: 'action',
                tdClass: 'text-center',
                thClass:'text-center',
                sortable: false,
            })
        }
    },

    methods: {
        openCalendar() {
            const inputElement = this.$refs.monthPicker;
            inputElement.showPicker?.() || inputElement.focus();
        },

        formatDateToLocal(date) {
            // Format date to YYYY-MM-DD in the local timezone
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },

        handleMonthChange() {
            if (!this.selectedMonth) return;

            const [year, month] = this.selectedMonth.split("-");
            const startDate = new Date(year, month - 1, 1); // First day of the month
            const endDate = new Date(year, month, 0);       // Last day of the month

            this.result = {
                year,
                monthName: this.monthNames[month - 1], // Get month name using the array
                startDate: this.formatDateToLocal(startDate), // Format as local date
                endDate: this.formatDateToLocal(endDate),     // Format as local date
                total_amount: null,
                total_vat: null,
                total_grand_total: null,
            }
        },

        getTimeStamp(time) {
            return moment(time).format('MMMM Do YYYY, h:mm:ss a')
        },

        getFilterByType() {
            if(this.type !== null) {
                this.$store.dispatch('hearts/fetchBillingListBytype', this.type)
                this.gender = null
                this.start_date = null
                this.end_date = null
            } else {
                this.$store.dispatch('hearts/fetchBillingList')
            }
        },

        getFilterByGender() {
            if(this.gender !== null) {
                this.$store.dispatch('hearts/fetchBillingListByGender', this.gender)
                this.type = null
                this.start_date = null
                this.end_date = null
            } else {
                this.$store.dispatch('hearts/fetchBillingList')
            }
        },

        getFilterByDate() {
            if(this.start_date !== null && this.end_date !== null) {
                this.$store.dispatch('hearts/fetchBillingListByBothDate', {start_date: this.start_date, end_date: this.end_date})
                this.type = null
                this.gender = null
            } else if (this.start_date !== null && this.end_date == null) {
                this.$store.dispatch('hearts/fetchBillingListByStartDate', this.start_date)
                this.type = null
                this.gender = null
            } else if (this.start_date == null && this.end_date !== null) {
                this.$store.dispatch('hearts/fetchBillingListByEndDate', this.end_date)
                this.type = null
                this.gender = null
            } else {
                this.$store.dispatch('hearts/fetchBillingList')
            }
        },

        getReceiptInfo(rcpt) {
            this.receipt.id = rcpt.id
            this.receipt.invoice_to = rcpt.user.first_name + ' ' + rcpt.user.last_name
            this.receipt.charge = rcpt.amount
            this.receipt.vat = rcpt.vat
            this.receipt.grand_total = rcpt.grand_total
        },

        getReport() {
            this.$store.dispatch('hearts/fetchMonthlyReport', {start_date: this.result.startDate, end_date: this.result.endDate}).then(response => {
                if(response.data.code == 200) {
                    this.result.total_amount = response.data.data.total_amount
                    this.result.total_vat = response.data.data.total_vat
                    this.result.total_grand_total = response.data.data.total_grand_total
                } else {
                    this.result = null
                }
            })
        },

        async download(divId) {
            const element = document.getElementById(divId);
            element.classList.add('pdf-export');
            console.log(element)
            const opt = {
                margin: [10, 20, 20, 20],
                filename: 'biyerjuti-report.pdf',
                image: {
                    type: 'jpeg',
                    quality: 1
                },
                html2canvas: {
                    dpi: 192,
                    letterRendering: true,
                    scale: 2,
                    scrollY: 0
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                },
            };
            setTimeout(() => {
                element.classList.remove('pdf-export');
                html2pdf().from(element).set(opt).save();
                this.visible = false;
                this.element = ''
            }, 50);
        }
    }
}
</script>
  
<style lang="scss" >
#month-picker {
  cursor: pointer; /* Ensures entire input looks clickable */
}

#receiptbody {
    min-height: 297mm;
    /* width: 722px; */
    padding: 2mm;
    box-sizing: border-box;
}

#reportbody {
    min-height: 297mm;
    /* width: 722px; */
    padding: 2mm;
    box-sizing: border-box;
}

.pdf-export {
    width: 722px;
}

.html2pdf__page-break {
    display: block;
    page-break-before: always;
}

.responsive-table {
    overflow-x: hidden;
}

.no-outline{
    border:none !important;
}

.f20{
    font-size: 8px !important;
}

// .vs__dropdown-menu {
//   height: 200px;
// }

.search-gap {
    margin-left: 10px;
    margin-right: 10px;
}

.dropdown-size {
    width: 285px;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>